import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import NewsViewBox from '@/components/NewsViewBox.vue';
import Pagination from '@/components/Pagination.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
import moment from 'moment';
var NewsCenter = (function (_super) {
    __extends(NewsCenter, _super);
    function NewsCenter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.slideIndex = 0;
        _this.page = 1;
        _this.rows = 6;
        _this.newsStick = {};
        _this.moment = moment;
        return _this;
    }
    NewsCenter.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, detailsModule.getNewsList({
                                page: 1,
                                rows: 3,
                                id: 20,
                                stick: 1
                            }).then(function (result) { return result.rows; })];
                    case 1:
                        _a.newsStick = _b.sent();
                        this.getNewsList();
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(NewsCenter.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsCenter.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsCenter.prototype, "currentID", {
        get: function () {
            return detailsModule.newsID;
        },
        set: function (val) {
            detailsModule.setNewsID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsCenter.prototype, "newsList", {
        get: function () {
            return detailsModule.newsList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsCenter.prototype, "total", {
        get: function () {
            return detailsModule.newsTotal;
        },
        enumerable: false,
        configurable: true
    });
    NewsCenter.prototype.pageWatch = function () {
        this.getNewsList();
    };
    NewsCenter.prototype.slideClick = function (val) {
        this.slideIndex = val;
    };
    NewsCenter.prototype.setPage = function () {
        this.page = 1;
    };
    NewsCenter.prototype.getNewsList = function () {
        detailsModule.getNewsList({
            page: this.page,
            rows: this.rows,
            id: this.currentID,
            stick: ''
        });
    };
    __decorate([
        Watch('$data.page')
    ], NewsCenter.prototype, "pageWatch", null);
    NewsCenter = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList, NewsViewBox: NewsViewBox, Pagination: Pagination }
        })
    ], NewsCenter);
    return NewsCenter;
}(Vue));
export default NewsCenter;
