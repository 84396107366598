import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
var NewsViewBox = (function (_super) {
    __extends(NewsViewBox, _super);
    function NewsViewBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.moment = moment;
        return _this;
    }
    __decorate([
        Prop({ required: true })
    ], NewsViewBox.prototype, "data", void 0);
    NewsViewBox = __decorate([
        Component
    ], NewsViewBox);
    return NewsViewBox;
}(Vue));
export default NewsViewBox;
